import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Uploader from 'src/components/Uploader'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'
import UploadIcon from 'src/icons/UploadIcon'

import { setReportPayload } from '../../ducks/report.reducer'

import ImagePreview from './ImagePreview'

const CardBackFile: React.FC = () => {
  const { payload } = useAppSelect((state) => state.reports)
  const dispatch = useAppDispatch()

  const preview = useMemo(() => {
    if (!payload.backImage) {
      return ''
    }

    return URL.createObjectURL(payload.backImage?.[0])
  }, [payload.backImage])

  const setImage = (files: FileList | null) => {
    const ext = files?.[0].name.split('.').pop()?.toLocaleLowerCase?.()

    if (ext && ext !== 'png' && ext !== 'jpg' && ext !== 'jpeg') {
      alert('Invalid file type. Please select a valid image file')
      return
    }

    dispatch(
      setReportPayload({
        ...payload,
        backImage: files,
      })
    )
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography color="bw.greyLightest2">
        Photo of back of card{' '}
        <Box component="span" sx={{ color: 'bw.red' }}>
          *
        </Box>
      </Typography>
      {preview ? (
        <ImagePreview
          src={preview}
          onDelete={() => {
            setImage(null)
          }}
        />
      ) : (
        <Uploader
          sx={{
            width: '100%',
            height: '183px',
            backgroundColor: 'bw.white',
            color: 'bw.blackLightest',
            textTransform: 'none',
            border: '1px solid',
            borderColor: 'bw.blackLightest',
            display: 'flex',
            flexDirection: 'column',
          }}
          startIcon={<UploadIcon sx={{ width: 31, height: 31 }} />}
          placeholder="Upload Back Image"
          variant="text"
          onFileSelect={(event) => {
            event.preventDefault()

            setImage(event.target.files)
          }}
          multiple={false}
          accept="image/png, image/jpg, image/jpeg"
        />
      )}
    </Box>
  )
}

export default CardBackFile
