import { configureStore } from '@reduxjs/toolkit'
import homeReducer from 'src/features/home/ducks/home.reducer'
import reportReducer from 'src/features/report/ducks/report.reducer'

import accessTokenReducer from './accessToken/accessToken.reducer'
import schemeReducer from './scheme/scheme.reducer'

const store = configureStore({
  reducer: {
    home: homeReducer,
    access_token: accessTokenReducer,
    schemes: schemeReducer,
    reports: reportReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
  },
  devTools: process.env.NODE_ENV === 'development',
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
