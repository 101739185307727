import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'

import AppErrorBoundry from './components/ErrorBoundary'
import { store } from './libs'
import routes from './route'
import theme from './theme'

const App: React.FC = () => {
  const content = useRoutes(routes)
  return (
    <HelmetProvider>
      <Helmet
        title={process.env.REACT_APP_NAME || 'CSCS Smart Check'}
        defaultTitle={process.env.REACT_APP_NAME || 'CSCS Smart Check'}
      />
      <AppErrorBoundry>
        <Provider store={store}>
          <ThemeProvider theme={theme}>{content}</ThemeProvider>
        </Provider>
      </AppErrorBoundry>
    </HelmetProvider>
  )
}

export default App
