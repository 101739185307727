/* eslint-disable prefer-promise-reject-errors */
export interface IPosition {
  latitude: number
  longitude: number
}

export const getLocation = async (): Promise<IPosition> => {
  return await new Promise((resolve, reject) => {
    const position: IPosition = {
      latitude: 0,
      longitude: 0,
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          position.latitude = pos.coords.latitude
          position.longitude = pos.coords.longitude

          resolve(position)
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            reject(
              'We required your location in order to use the CSCS SmartCheck'
            )
          }
        }
      )
    } else {
      alert('Geolocation is not supported by this browser.')
      reject('Geolocation is not supported by this browser.')
    }
  })
}
