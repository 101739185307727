import React, { useMemo } from 'react'
import { Box, FormControl, FormLabel } from '@mui/material'
import Scheme from 'src/components/Scheme'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'

import { setReportPayload } from '../../ducks/report.reducer'

interface ISchemeValue {
  schemeName: string
  schemeIdentifier: string
}

const SchemeInput: React.FC = () => {
  const dispatch = useAppDispatch()
  const { payload } = useAppSelect((state) => state.reports)
  const { scheme } = useAppSelect((state) => state.schemes)

  const value = useMemo<ISchemeValue>(() => {
    if (!payload.schemeIdentifier) {
      return {
        schemeName: '',
        schemeIdentifier: '',
      }
    }

    // find scheme in schemes
    const [currentScheme] = scheme.filter(
      (s) => s.schemeIdentifier === payload.schemeIdentifier
    )
    return currentScheme
  }, [payload.schemeIdentifier])

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel>
        Select Scheme{' '}
        <Box component="span" sx={{ color: 'bw.red' }}>
          *
        </Box>
      </FormLabel>
      <Scheme
        value={value}
        onChange={(value) => {
          dispatch(
            setReportPayload({
              ...payload,
              schemeIdentifier: value?.schemeIdentifier || '',
            })
          )
        }}
      />
    </FormControl>
  )
}

export default SchemeInput
