import React from 'react'

import NotFoundPage from './pages/404'
import Home from './pages/home'
import Report from './pages/report'
import Support from './pages/support'

interface IRoutes {
  element: React.ReactNode
  path: string
}

const routes: IRoutes[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/support',
    element: <Support />,
  },
  {
    path: '/report-card',
    element: <Report />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
]

export default routes
