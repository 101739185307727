export interface IReportPayload {
  message?: string
  backImage?: FileList | null
  frontImage?: FileList | null
  schemeIdentifier: string
  captchaToken: string
  reporterName: string
  reporterEmail: string
  reporterPhoneNumber: string
  customerName: string
  registrationNumber: string
  cardTypeName: string
}

interface IReportState {
  loading: boolean
  message: string
  payload: IReportPayload
}

export const initialState: IReportState = {
  loading: false,
  message: '',
  payload: {
    schemeIdentifier: '',
    message: '',
    captchaToken: '',
    backImage: null,
    frontImage: null,
    reporterName: '',
    reporterEmail: '',
    reporterPhoneNumber: '',
    customerName: '',
    registrationNumber: '',
    cardTypeName: ''
  },
}
