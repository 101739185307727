import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import IphoneIcon from 'src/icons/IphoneIcon'
import PatternIcon2 from 'src/icons/PatternIcon2'
import { ExtraLinks } from 'src/utils/extraLinks'

import Image from '../Image'
import Link from '../Link'

const MobileApp: React.FC = () => {
  return (
    <Stack
      sx={{
        position: 'relative',
        backgroundImage: 'url(/images/blue-bg.png)',
        backgroundSize: '105% 105%',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minHeight: '725px',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        component="div"
        sx={{ position: 'relative', width: '100%', pointerEvents: 'none' }}
      >
        <PatternIcon2
          sx={{
            position: 'absolute',
            width: 340,
            height: 720,
            right: 0,
            translate: '0 -75%',
            pointerEvents: 'none',
          }}
        />
      </Box>
      <Stack
        component="div"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '70%', margin: (theme) => theme.spacing(0, 'auto') }}
      >
        <Stack flex={0.1}>
          <IphoneIcon sx={{ width: 582, height: 582 }} />
        </Stack>
        <Stack
          component="div"
          sx={{
            height: '100% !important',
            maxWidth: '50%',
          }}
          direction="column"
          alignItems="flex-start"
          flex={1}
          spacing={20}
        >
          <Typography
            fontSize={(theme) => theme.typography.fontSize * 4}
            fontWeight="bold"
            textAlign="left"
            color="bw.white"
            fontFamily="MetaProBold"
          >
            Download the CSCS Smart Check app
          </Typography>
          <Typography textAlign="left" color="bw.white" maxWidth="76%">
            The CSCS Smart Check app allows all 2.1 million cards displaying the
            CSCS logo to be verified using a single interface, providing
            employers with a quick, easy and secure way of ensuring everyone has
            the right card for the job they do on site.
          </Typography>
          <Stack direction="row" spacing={10} justifyContent="center">
            <Link
              to={ExtraLinks.playStore}
              sx={{ width: '160px', height: '50px' }}
            >
              <Image src="/images/play-store.png" sx={{ objectFit: 'fill' }} />
            </Link>
            <Link
              to={ExtraLinks.appStore}
              sx={{ width: '170px', height: '50px' }}
            >
              <Image src="/images/app-store.png" sx={{ objectFit: 'fill' }} />
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MobileApp
