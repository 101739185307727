import React from 'react'
import { FormControl, FormLabel } from '@mui/material'
import Scheme from 'src/components/Scheme'
import { useAppDispatch, useAppSelect } from 'src/hooks/useRedux'
import { type IScheme } from 'src/utils/types/scheme'

import { setPayload } from '../../ducks/home.reducer'

const SchemeInput: React.FC = () => {
  const dispatch = useAppDispatch()
  const { payload } = useAppSelect((state) => state.home)

  const value: IScheme = {
    schemeName: payload.schemeName || '',
    schemeIdentifier: payload.schemeIdentifier || '',
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel
        sx={{
          fontSize: (theme) => theme.typography.fontSize + 2,
          fontWeight: (theme) => theme.typography.fontWeightRegular,
        }}
      >
        Select Scheme<span style={{ color: '#F90000' }}>*</span>
      </FormLabel>
      <Scheme
        value={value}
        onChange={(value) => {
          dispatch(
            setPayload({
              ...payload,
              schemeName: value?.schemeName || '',
              schemeIdentifier: value?.schemeIdentifier || '',
            })
          )
        }}
      />
    </FormControl>
  )
}

export default SchemeInput
