import { createAsyncThunk } from '@reduxjs/toolkit'
import http from 'src/libs/axios'
import { type IAccessTokenResponse } from 'src/utils/types/access_token'

export const getAccessToken = createAsyncThunk(
  'getAccessToken',
  async (_, thunkApi) => {
    try {
      const { response, error } = await http({
        method: 'GET',
        path: {
          url: 'GET_ACCESS_TOKEN',
        },
        authToken: `Basic ${process.env.REACT_APP_API_AUTH_TOKEN}`,
      })

      if (error) {
        return thunkApi.rejectWithValue(error)
      }

      const { responseCode, responseMessage, responseData } =
        response?.data as IAccessTokenResponse

      if (responseCode !== '200') {
        return thunkApi.rejectWithValue(responseMessage)
      }

      return thunkApi.fulfillWithValue(responseData)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  }
)
