/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import Recaptcha, { type ReCAPTCHA } from 'react-google-recaptcha'

export interface ICaptchaRef {
  resetCaptcha: () => void
}

interface ICaptchaProps {
  ref: any
  onChange: (token: string | null) => void
  isLoading?: boolean
}

const Captcha: React.FC<ICaptchaProps> = forwardRef((props, ref) => {
  const captchaRef = useRef<ReCAPTCHA>(null)

  useImperativeHandle(ref, () => ({
    resetCaptcha: () => {
      captchaRef.current?.reset?.()
    },
  }))

  return (
    <Recaptcha
      style={{
        width: '100%',
        transform: 'scale(1, 1)',
        transformOrigin: '0 0',
        ...(props.isLoading && { opacity: 0.5, filter: 'grayscale(100%)', pointerEvents: 'none' })
      }}
      onChange={props.onChange}
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
      ref={captchaRef}
    />
  )
})

export default Captcha
